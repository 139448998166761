<template>
  <section>
    <b-row class="match-height">
      <b-col lg="6" md="12">
        <b-card>
          <b-card-title class="mb-1"> NEW USER DETAILS </b-card-title>
          <b-alert
            variant="danger"
            class="mb-0"
            :show="alertMessage == '' ? false : true"
          >
            <div class="alert-body text-center">{{ alertMessage }}</div>
          </b-alert>
          <b-row>
            <b-col sm="12" class="mb-1">
              <!-- form -->
              <validation-observer ref="usercreateForm">
                <b-form class="mt-2" @submit.prevent>
                  <!-- username -->
                  <b-form-group label="Username" label-for="username">
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required|min:5"
                    >
                      <b-form-input
                        id="username"
                        v-model="createForm.username"
                        :state="errors.length > 0 ? false : null"
                        name="username"
                        placeholder="john"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- email -->
                  <b-form-group label="Email" label-for="user-email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="user-email"
                        v-model="createForm.email"
                        :state="errors.length > 0 ? false : null"
                        name="user-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- password -->
                  <b-form-group label="New Password" label-for="user-password">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required|min:5"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="user-password"
                          v-model="createForm.password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          type="password"
                          name="user-password"
                          placeholder="············"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-form>
              </validation-observer>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validatecreateForm()"
              >
                Create
                <b-spinner small v-show="showOverlay" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click.prevent="resetcreateForm()"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import {
  BOverlay,
  VBTooltip,
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BSpinner,
  BAlert,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCol,
    BRow,
    BTable,
    BAvatar,
    BCard,
    BBadge,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    VBTooltip,
    BCardBody,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showOverlay: false,
      alertMessage: "",
      pageAction: "create",

      required,
      email,
      createForm: {
        username: "",
        email: "",
        password: "",
      },
    };
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {},
  mounted() {},
  methods: {
    validatecreateForm() {
      // Validate Form
      this.$refs.usercreateForm.validate().then((success) => {
        if (success) {
          // Hide the modal manually
          this.$nextTick(() => {
            this.addaccount();
          });
        }
      });
    },
    async addaccount() {
      this.showOverlay = true;
      useJwt.setAction("createUser");
      this.$http
        .post("/app", {
          username: this.createForm.username,
          email: this.createForm.email,
          password: this.createForm.password,
        })
        .then((response) => {
          this.showOverlay = false;
          if (response.data.result) {
            this.resetcreateForm();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User Created",
                icon: "UserPlusIcon",
                variant: "success",
                text: `${response.data.message}`,
              },
            });
          }
          if (!response.data.result) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Create Failed",
                icon: "XOctagonIcon",
                variant: "danger",
                text: `${response.data.message}`,
              },
            });
          }
        });
    },
    resetcreateForm() {
      this.createForm.username = "";
      this.createForm.email = "";
      this.createForm.password = "";
      this.$refs.usercreateForm.reset();
    },
  },
};
</script>
